import React, { useState } from "react";
import { Translation } from "react-i18next";
import data from '../../data/Sha_FAQs.json';
import ShakaijinAccordion from "../sections/ShakaijinAccordion";
import ShaHeader from "../layouts/ShaHeader";



const ShakaijinFAQs = () => {

    // const changeLang = (e) => {
    //     const newLang = e.target.value;
    //     i18n.changeLanguage(newLang);
    // }

    return (
        <Translation>
            {(t) => (

                <main className="ShakaijinFAQ">
                    <header className="faq-hero-header faq-grid">
                        <div className="faq-header-content">
                            <ShaHeader />

                            <div className="faq-sha-landing-hero-content">
                                <h1 className="faq-sha-title">{t("Frequently Asked Questions")}</h1>
                                <a href="/shakaijin"><button className="faq-sha-join-program-btn btn">{t("Program Details")}</button></a>
                            </div>
                        </div>
                    </header>

                    <div className="sha-faq-body">
                        <div className="about-section">
                            <h1 className="about-title">{t("About The Program")}</h1>
                            <p className="about-text">{t("The program is about building tech startups by connecting business co-founders from Japan with technical co-founders outside Japan.")} <br></br>
                                {t("We are looking to make tech startups - websites and app based startups. Our target for the first batch of startups is to build startups which solve problems in Japan.")}</p>
                        </div>

                        <div className="FAQs-section">
                            <h1 className="FAQs-header">{t("Our FAQs")}</h1>

                            <div className="faq-table-contents smooth-scroll">
                                <a href="#section1">{t("About The Program")}</a> <br></br>
                                <a href="#section2">{t("Why Should I Participate?")} </a><br></br>
                                <a href="#section3">{t("Program Outcomes")} </a><br></br>
                                <a href="#section4">{t("Who We Are")} </a><br></br>
                                <a href="#section5">{t("Team")} </a><br></br>
                                <a href="#section6">{t("Legal")} </a ><br></br>
                                <a href="#section7">{t("Timeline")} </a ><br></br>
                                <a href="#section8">{t("Mentorship")} </a ><br></br>
                                <a href="#section9">{t("Funding")} </a > <br></br>
                            </div >

                            <div className="shakaijin_faq_dropdowns">
                                <h1 className="dropdown-section-title" id="section1">{t("About The Program")}</h1>
                                {data["About the Program"].map((section) => {
                                    return <ShakaijinAccordion data={section} />
                                })}

                                <h1 className="dropdown-section-title" id="section2">{t("Why Should I Participate?")}</h1>
                                {data["Why You Should Participate"].map((section) => {
                                    return <ShakaijinAccordion data={section} />
                                })}

                                <h1 className="dropdown-section-title" id="section3">{t("Program Outcomes")}</h1>
                                {data["Outcome of the Program"].map((section) => {
                                    return <ShakaijinAccordion data={section} />
                                })}

                                <h1 className="dropdown-section-title" id="section4">{t("Who We Are")}</h1>
                                {data["Who We Are"].map((section) => {
                                    return <ShakaijinAccordion data={section} />
                                })}

                                <h1 className="dropdown-section-title" id="section5">{t("Team")}</h1>
                                {data["Team"].map((section) => {
                                    return <ShakaijinAccordion data={section} />
                                })}

                                <h1 className="dropdown-section-title" id="section6">{t("Legal")}</h1>
                                {data["Legal"].map((section) => {
                                    return <ShakaijinAccordion data={section} />
                                })}

                                <h1 className="dropdown-section-title" id="section7">{t("Timeline")}</h1>
                                {data["Timeline"].map((section) => {
                                    return <ShakaijinAccordion data={section} />
                                })}

                                <h1 className="dropdown-section-title" id="section8">{t("Mentorship")}</h1>
                                {data["Mentorship"].map((section) => {
                                    return <ShakaijinAccordion data={section} />
                                })}

                                <h1 className="dropdown-section-title" id="section9">{t("Funding")}</h1>
                                {data["Funding"].map((section) => {
                                    return <ShakaijinAccordion data={section} />
                                })}



                            </div>
                        </div >
                    </div >


                </main >
            )}

        </Translation>

    )
}

export default ShakaijinFAQs