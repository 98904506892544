import React from "react";
import { Translation } from "react-i18next";
import Accordion from "../widgets/Accordion";

const FAQs = () => {
  return (
    <main className="faq">
      <div className="faq__header">
        <Translation>
          {(t) => (
            <h1 className="faq__header__subheading">{t("What we ask")}</h1>
          )}
        </Translation>
        <Translation>
          {(t) => (
            <h1 className="faq__header__heading">
              {t("We ")}
              <span>{t("Answer")}</span>
            </h1>
          )}
        </Translation>

        <Translation>
          {(t) => (
            <h4 className="faq__header__info">
              {t("What other's are curious to know about us")}
            </h4>
          )}
        </Translation>
      </div>

      <div className="faq__content">
        <Accordion data={[1, "Title1", "Desc"]} />
        <Accordion data={[2, "Title2", "Desc"]} />
        <Accordion data={[3, "Title3", "Desc"]} />
      </div>
    </main>
  );
};

export default FAQs;
