import React from "react";
import i18n from "i18next";
import { Translation } from "react-i18next";

const ShaFooter = () => {
    return (
        <Translation >
            {(t) => (
                <main className="">
                    <div className="shakaijin-footer-bg">
                        <div className="">
                            <div className="">
                                <label className="sha-footer-txt-1">{t("Ready to join?")}</label><br></br>
                                <a href="https://forms.gle/eRvyF9EKwYwwisM29" target="_blank" rel="noopener noreferrer">
                                <button id="btn-shakaijin-reg-now" className="btn">{t("Register Now")}</button>
                                </a>
                            </div>
                        <br></br><br></br>
                            <div className="">
                                <h2 className="sha-footer-txt-2">{t("Enquire with us")}</h2>
                                <a href="/shakaijin/faqs">
                                    <button onclick="alert('eee')" className="btn" id="btn-shakaijin-faqs">{t("Check out FAQs")}</button><br></br>
                                </a>
                                <a href="mailto:arpit@investocracy.co?subject=Query%20regarding%20Shakaijin%20program" target="_blank" rel="noopener noreferrer">
                                    <button type="submit" className="btn" id="btn-shakaijin-enquire-submit">{t("Contact Us")}</button>
                                    </a>
                            </div>
                        </div>

                    </div>

                </main >
            )}

        </Translation>
    )
}

export default ShaFooter