import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translations files
import EnglishTranlations from "./translations/data.en.json";
import JapanTranlations from "./translations/data.jp.json";

// the translations
const resources = {
  en: EnglishTranlations,
  jp: JapanTranlations,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // Checks if value is default lanuage present or not and if presents, sets to it
    lng:
      localStorage.getItem("currLang") != null
        ? localStorage.getItem("currLang")
        : "en",

    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },

    react: {
      wait: true,
    },
  });

export default i18n;
