import React, { useEffect, useState, Suspense } from "react";
import axios from "axios";
import loadable from "@loadable/component";
import LazyLoader from "../utils/LazyLoader";

const ReportShowcase = (props) => {
  // const { reportId, imageUrls } = props.location.state;
  const [mainSlidshowImage, setMainSlideShowImage] = useState(0);
  const [reportId, setReportId] = useState("");
  const [imageUrls, setImageUrls] = useState([]);

  // console.log(window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]);

  const slug = window.location.pathname.split("/")[
    window.location.pathname.split("/").length - 1
  ];

  // console.log(slug);

  useEffect(() => {
    console.log(`${process.env.REACT_APP_NODE_API_URL}/report/${slug}`)
    // 1. Fetching Reportwise Data
    axios({
      method: "get",
      url: `${process.env.REACT_APP_NODE_API_URL}/report/${slug}`,
    })
      .then((res) => {
        // console.log(res.data.data);
        setImageUrls(res.data.data.imageUrls);
        setReportId(res.data.data.reportId);
        // console.log(imageUrls);
        // console.log(reportId);
      })
      .catch((err) => {
        console.log(err);
      });

    // 2. Adding gumroad script
    const script = document.createElement("script");
    script.src = "https://gumroad.com/js/gumroad-embed.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const changeImage = (index) => (e) => {
    // console.log(e.target.src);
    // console.log(index);
    setMainSlideShowImage(index);
  };

  return (
    <main className="reportshowcase">
      <section className="reportshowcase__header">
        <div className="reportshowcase__header__slidshow">
          <img
            src={imageUrls[mainSlidshowImage]}
            className="reportshowcase__header__slidshow__image__main"
          />
          <div className="reportshowcase__header__slidshow__image__grid">
            {imageUrls.map((img, key) => {
              return (
                <img
                  src={img}
                  className="reportshowcase__header__slidshow__image__grid__item"
                  onClick={changeImage(key)}
                  key={key}
                />
              );
            })}
          </div>
        </div>
      </section>

      {reportId ? (
        <section className="reportshowcase__content">
          <div
            className="gumroad-product-embed"
            data-gumroad-product-id={reportId}
          >
            <a
              href={`https://gumroad.com/l/${reportId}`}
              className="reportshowcase__content__report__link"
            >
              Let The Report Load...
            </a>
          </div>
        </section>
      ) : (
        <LazyLoader />
      )}
    </main>
  );
};

export default ReportShowcase;
