import React from "react";
import { Link } from "react-router-dom";
import Header from "../reports/Header";
import Item from "../reports/Item";
// import Popup from "../reports/Popup";

const Reports = () => {
  return (
    <main className="reports">
      <div className="reports__content">
        {/* Financial Technology */}
        <div className="reports__content__grid">
          <Header
            data={[
              "Latest Reports on Financial Technology",
              "Financial Technology",
            ]}
          />

          <div className="reports__content__grid__content">
            <Link
              to={{
                pathname: "/reports/fintech-startup-landscape-report-in-africa-2020",
                state: {
                  reportId: "FBnju",
                  imageUrls: [
                    "https://cssdeck.com/uploads/media/items/2/2rT2vdx.jpg",
                    "https://images.unsplash.com/photo-1565073182887-6bcefbe225b1?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max",
                    "https://images.pexels.com/photos/417173/pexels-photo-417173.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
                    "https://res.cloudinary.com/dk-find-out/image/upload/q_80,w_1920,f_auto/A-Alamy-BXWK5E_vvmkuf.jpg",
                  ],
                },
              }}
              className="reports__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "Fin-Tech Startup Landscape Report in Africa - 2020",
                  ["Coming Soon"],
                ]}
              />
            </Link>

            <Link
              to={{
                pathname: "/reports/fintech-startup-landscape-report-in-singapore-2020",
                productdetailProps: {
                  productdetail: "I M passed From Props",
                },
              }}
              className="reports__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "Fin-Tech Startup Landscape Report in Singapore - 2020",
                  ["Coming Soon"],
                ]}
              />
            </Link>

            <Link
              to={{
                pathname: "/reports/fintech-startup-landscape-report-in-india-2020",
              }}
              className="reports__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "Fin-Tech Startup Landscape Report in India - 2020",
                  ["Coming Soon"],
                ]}
              />
            </Link>
          </div>
        </div>

        {/* Medical Technology */}
        <div className="reports__content__grid">
          <Header
            data={[
              "Latest Reports on Medical Technology",
              "Medical Technology",
            ]}
          />

          <div className="reports__content__grid__content">
            <a href="/" className="reports__content__grid__content__link">
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "Med-Tech Startup Landscape Report in Singapore - 2020",
                  ["Coming Soon"],
                ]}
              />
            </a>

            <a href="/" className="reports__content__grid__content__link">
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "Med-Tech Startup Landscape Report in South Korea - 2020",
                  ["Coming Soon"],
                ]}
              />
            </a>
          </div>
        </div>

        {/* Education Technology */}
        <div className="reports__content__grid">
          <Header
            data={[
              "Latest Reports on Education Technology",
              "Education Technology",
            ]}
          />

          <div className="reports__content__grid__content">
            <a href="/" className="reports__content__grid__content__link">
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "Ed-Tech Startup Landscape Report in Africa - 2020",
                  ["Coming Soon"],
                ]}
              />
            </a>

            <a href="/" className="reports__content__grid__content__link">
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "Ed-Tech Startup Landscape Report in India - 2020",
                  ["Coming Soon"],
                ]}
              />
            </a>

            <a href="/" className="reports__content__grid__content__link">
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "Ed-Tech Startup Landscape Report in Singapore - 2020",
                  ["Coming Soon"],
                ]}
              />
            </a>
          </div>
        </div>

        {/* Master Reports */}
        <div className="reports__content__grid">
          <Header data={["Geography", "Latest Master Reports"]} />

          <div className="reports__content__grid__content">
            <a href="/" className="reports__content__grid__content__link">
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "Startup Landscape Report in Africa - 2020",
                  ["Coming Soon"],
                ]}
              />
            </a>

            <a href="/" className="reports__content__grid__content__link">
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "Startup Landscape Report in Togo - 2020",
                  ["Coming Soon"],
                ]}
              />
            </a>

            <a href="/" className="reports__content__grid__content__link">
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "Startup Landscape Report in India - 2020",
                  ["Coming Soon"],
                ]}
              />
            </a>

            <a href="/" className="reports__content__grid__content__link">
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "Startup Landscape Report in Taiwan - 2020",
                  ["Coming Soon"],
                ]}
              />
            </a>
          </div>
        </div>

        {/* Sector Reports */}
        <div className="reports__content__grid">
          <Header
            data={["Geography", "Artificial Intelligence Startup Reports"]}
          />

          <div className="reports__content__grid__content">
            <a href="/" className="reports__content__grid__content__link">
              <Item
                data={[
                  "assets/images/fintech-india-2020.png",
                  "AI Startups in Asia - 2020",
                  ["Coming Soon"],
                ]}
              />
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Reports;
