import React, { useState } from "react";
import axios from "axios";
import config from "../../config";

const Contactus = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [userType, setUserType] = useState("");
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const handleForm = (e) => {
    const { name, value } = e.currentTarget;

    if (name === "name") {
      //   console.log("working");
      setName(value);
    } else if (name === "email") {
      //   console.log("working");
      setEmail(value);
    } else if (name === "companyName") {
      //   console.log("working");
      setCompanyName(value);
    } else if (name === "companyWebsite") {
      //   console.log("working");
      setCompanyWebsite(value);
    } else if (name === "userType") {
      //   console.log("working");
      setUserType(value);
    } else if (name === "message") {
      //   console.log("working");
      setMessage(value);
    }
  };

  //   const changeLang = (e) => {
  //     console.log(e.target.value);
  //   };

  const submitForm = (e) => {
    e.preventDefault();
    // console.log("Workign");
    setIsSubmitClicked(true);
    const payload = {
      name,
      email,
      companyName,
      companyWebsite,
      userType,
      message,
    };
    // console.log(config.REACT_APP_NODE_API_URL);

    axios({
      method: "post",
      url: `${config.REACT_APP_NODE_API_URL}/api/user/contact`,
      data: payload,
    })
      .then((res) => {
        console.log(res.data.data);
        setMessageSent(true);
        // console.log(imageUrls);
        // console.log(reportId);
        setTimeout(() => {
          setMessageSent(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   console.log(name)

  return (
    <section className="contactus">
      {!messageSent ? (
        <>
          <div className="contactus__header">
            <h1 className="heading-primary--main contactus__header__heading">
              Contact Us
            </h1>
          </div>
          <div className="contactus__content">
            <form className="contactus__content__form">
              <label className="contactus__content__form__label">
                Enter your name
              </label>
              <input
                type="text"
                className="contactus__content__form__input"
                name="name"
                onChange={handleForm}
              />
              <label className="contactus__content__form__label">
                Enter your Company Website
              </label>
              <input
                type="email"
                className="contactus__content__form__input"
                name="email"
                onChange={handleForm}
              />
              <label className="contactus__content__form__label">
                Enter company name
              </label>
              <input
                type="text"
                className="contactus__content__form__input"
                name="companyName"
                emailcompany
                onChange={handleForm}
              />
              <label className="contactus__content__form__label">
                Enter company website
              </label>
              <input
                type="text"
                textarea
                className="contactus__content__form__input"
                name="companyWebsite"
                onChange={handleForm}
              />

              <label className="contactus__content__form__label">
                Which one is you?
              </label>
              <div className="contactus__content__form__radio">
                <input
                  type="radio"
                  id="investor"
                  name="userType"
                  value="investor"
                  className="contactus__content__form__radio__item"
                  onChange={handleForm}
                />
                <label
                  htmlFor="investor"
                  className="contactus__content__form__radio__item__label"
                >
                  Investor
                </label>

                <input
                  type="radio"
                  submitForm
                  id="founder"
                  name="userType"
                  value="founder"
                  className="contactus__content__form__radio__item"
                  onChange={handleForm}
                />
                <label
                  htmlFor="founder"
                  className="contactus__content__form__radio__item__label"
                >
                  Founder
                </label>
              </div>

              <label className="contactus__content__form__label">
                Your Message
              </label>
              <textarea
                type="text"
                rows="15"
                name="message"
                className="contactus__content__form__textarea"
                onChange={handleForm}
              />
              <button
                className="contactus__content__form__button btn"
                onClick={submitForm}
              >
                {!isSubmitClicked ? "Submit" : "Submitting"}
              </button>
            </form>
          </div>
        </>
      ) : (
          <div className="contactus__message">
            <h1 className="heading-primary--main">Message Sent</h1>
          </div>
        )}
    </section>
  );
};

export default Contactus;
