import React from "react";
import { Translation } from "react-i18next";

const AboutUs = () => {
  return (
    <div className="aboutus">
      <div className="aboutus__content">
      <Translation>
        {(t) => (
          <h1 className="heading-primary--main u-margin-bottom-small aboutus__content__heading">
            {t("About us")}
          </h1>
        )}
      </Translation>

      <Translation>
        {(t) => (
          <h2 className="heading-secondary--main u-margin-bottom-small aboutus__content__text">
            {t(
              "Investocracy is a platform to offer comprehensive Venture Capital deal discovery services for investors in Japan and investment readiness services to Startups in Africa & Asia"
            )}
          </h2>
        )}
      </Translation>
      <div className="aboutus__content__image">
        <img
          src="assets/images/connect-to-investors.png"
          className="aboutus__content__image__item" alt="Investocracy connects startups with investors"
        />
      </div>
      <Translation>
        {(t) => (
          <h3 className="heading-secondary--main u-margin-bottom-small aboutus__content__text">
            {t(
              "We are a team of Management Consultants, Investment Bankers and Venture Capitalists based in Japan who believe in the potential of emerging markets like Africa and South East Asia. Having a cumulative experience of 10 years working with Japan, having being accustomed to Japanese customs and way to living, we realized that there is a huge information gap between startups abroad and Japanese corporations. This is partly because of the language barrier."
            )}
          </h3>
        )}
      </Translation>

      <Translation>
        {(t) => (
          <h3 className="heading-secondary--main u-margin-bottom-small aboutus__content__text">
            {t(
              "We started Investocracy in Feb 2018 with the project to disseminate this gap and to serve as a bridge to connect innovative startups with Japan."
            )}
          </h3>
        )}
      </Translation>
      {/* 
      <div className="aboutus__content__image">
        <img
          src="assets/images/mindmap.png"
          className="aboutus__content__image__item"
        />
      </div>
      */}
      
      <div className="aboutus__content__teambutton">
      <a href="/team">
          <Translation>
            {(t) => (
              <button className="btn btn-md aboutus__cta__btn">
                {t("Meet the Team")}
              </button>
            )}
          </Translation>
        </a>
        </div>
      <Translation>
        {(t) => (
          <h1 className="heading-primary--main u-margin-bottom-small aboutus__content__heading" id="services">
            {t("Services")}
          </h1>
        )}
      </Translation>
      <div className="aboutus__content__image">
        <img
          src="assets/images/mindmap.png"
          className="aboutus__content__image__item" alt="Investocracy connects startups with investors"
        />
      </div>
      <h1>Investocracy</h1>
      <Translation>
        {(t) => (
          <h2 className="heading-secondary--main u-margin-bottom-small aboutus__content__text">
            {t(
              "Investment readiness and connection platform for startups to reach out to potential investors (in Japan). We help you prepare for your investment pitch deck, help it translate to Japanese, organize pitch events and present your startup in front of Japanese investors. We typically work with startups raising Seed/Series A/Series B investments"
            )}
          </h2>
        )}
      </Translation>

      <h1>Shakaijin Incubator Program</h1>
      <Translation>
        {(t) => (
          <h2 className="heading-secondary--main u-margin-bottom-small aboutus__content__text">
            {t(
              "Shakaijin is a Japanese term which means working class people in Japan. We operate the world's first online incubator program where global technical co-founders can partner with Japanese business co-founders to build a tech startup aiming at Japanese markets. The program is 12 weeks long and is conducted by the best of the industry Venture Capitalists and Mentors."
            )}
          </h2>
        )}
      </Translation>

      <h1>Connect</h1>
      <Translation>
        {(t) => (
          <h2 className="heading-secondary--main u-margin-bottom-small aboutus__content__text">
            {t(
              "World's first online platform for founders to help PRACTICE their pitches in front of actual investors. Pitching your startup is like dating, you are bound to get rejected early on. The more you practice, the better you get. Book a session with an actual investor and get valuable feedback from them on how you can improve."
            )}
          </h2>
        )}
      </Translation>

      <h1>Investocracy</h1>
      <Translation>
        {(t) => (
          <h2 className="heading-secondary--main u-margin-bottom-small aboutus__content__text">
            {t(
              "Startup market expansion services to Japan via helping connect locally successful startups with enterprising Japanese individuals so as to form a Joint Venture (JV) for market expansion to Japan. The target companies are pure-play SaaS companies with established markets outside Japan."
            )}
          </h2>
        )}
      </Translation>

      <h1>INVC.NEWS</h1>
      <Translation>
        {(t) => (
          <h2 className="heading-secondary--main u-margin-bottom-small aboutus__content__text">
            {t(
              "A niche news media platform, catering to an audience interested in the latest news on Startups from Asia and Africa. This includes news related to funding and finance, launch of new products and technology, the venture capital landscape as well as informative technical blogs and opinion columns."
            )}
          </h2>
        )}
      </Translation>

      <h1>Reports</h1>
      <Translation>
        {(t) => (
          <h2 className="heading-secondary--main u-margin-bottom-small aboutus__content__text">
            {t(
              "A portfolio of market intelligence products and reports covering the Asian and African startup landscape providing insights to investors regarding current players, technology and funding trends that help assess potential for investment. We also offer customized reports and market research studies on-demand."
            )}
          </h2>
        )}
      </Translation>
      
      {/* 
      <Translation>
        {(t) => (
          <h2 className="heading-secondary--main u-margin-bottom-small aboutus__content__text">
            {t(
              "- Events are conducted on a bi-monthly basis where up to 15 Startups have an opportunity to network and pitch  to an assorted audience of investors and enhance their visibility."
            )}
          </h2>
        )}
      </Translation>
      */}

      </div>
    </div>
  );
};

export default AboutUs;
