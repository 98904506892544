import React from "react";

const Accordion = (props) => {
  const { data } = props;
  return (
    <div className="tabs">
      <div className="tab">
        <input type="checkbox" id={data[0]} />
        <label className="tab-label" htmlFor={data[0]}>
          {data[1]}
        </label>
        <div className="tab-content">{data[2]}</div>
      </div>
    </div>
  );
};

export default Accordion;
