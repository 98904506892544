import React, { useState, useEffect } from "react";
// import SelectSearch from "react-select-search";
import axios from "axios";
import Select from "react-select";
import Loader from "../../utils/LazyLoader";
// import AsyncSelect from "react-select/async";
// import 'react-select/dist/react-select.css';
import "./style.css";

const Startups = () => {
  const [locations, setLocations] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [location, setLocation] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isNoDataFound, setIsNoDataFound] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [page, setPage] = useState(1);

  const [selectedOption, setSelectedOption] = useState(null);

  // Fetching the Countries
  useEffect(() => {
    console.log(`${process.env.REACT_APP_NODE_API_URL}/collect/getAllCountries`)
    // 1. Fetching Reportwise Data
    axios({
      method: "get",
      url: `${process.env.REACT_APP_NODE_API_URL}/collect/getAllCountries`,
    })
      .then((res) => {
        // console.log(res.data.data)
        setLocations(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Fetching the industries
  useEffect(() => {
    // 1. Fetching Reportwise Data
    axios({
      method: "get",
      url: `${process.env.REACT_APP_NODE_API_URL}/collect/getAllIndustries`,
    })
      .then((res) => {
        // console.log(res.data.data)
        setIndustries(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Set the hook value => Location
  const setLocationParams = (val) => {
    // console.log(val);
    setLocation(
      val
        ? val.map((el) => {
            // console.log(el)
            return el.value;
          })
        : []
    );
  };

  // Set the hook value => Industry
  const setIndustryParams = (val) => {
    // console.log(val);
    setIndustry(
      val
        ? val.map((el) => {
            // console.log(el)
            return el.value;
          })
        : []
    );
  };

  // Fetches the data for filter => Location
  useEffect(() => {
    // 1. Fetching Reportwise Data
    // console.log(location);

    // setFilteredData([]);
    // Fetch the data
    axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE_API_URL}/startup/`,
      data: {
        page,
        location,
        industry,
      },
    })
      .then((res) => {
        // console.log(res.data.data[0].data)
        const filteredArray = res.data.data[0].data.map((el) => {
          return el._id;
        });
        // console.log(filteredArray);
        setFilteredData(filteredArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location, page]);

  // Fetches the data for filter => Industry
  useEffect(() => {
    // 1. Fetching Reportwise Data
    // console.log(industry);

    // setFilteredData([]);
    // Fetch the data
    axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE_API_URL}/startup`,
      data: {
        page,
        location,
        industry,
      },
    })
      .then((res) => {
        const filteredArray = res.data.data[0].data.map((el) => {
          return el._id;
        });
        // console.log(filteredArray);
        setFilteredData(filteredArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [industry, page]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const getSearchData = () => {
    // console.log(`${process.env.REACT_APP_NODE_API_URL}/startup`);
    // e.preventDefault();

    setFilteredData([]);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_NODE_API_URL}/startup`,
      data: {
        page,
        search,
      },
    })
      .then((res) => {
        console.log(res.data.data);
        const data = {
          _id: res.data.data,
        };
        // console.log(data._id);
        setFilteredData(data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const countriesData = [
  //   { name: "India", value: "IN" },
  //   { name: "Japan", value: "JP" },
  //   { name: "China", value: "CH" },
  //   { name: "Singapore", value: "SP" },
  //   { name: "CANADA", value: "CN" },
  //   { name: "USA", value: "USA" },
  // ];

  useEffect(() => {
    setTimeout(() => {
      console.log(filteredData);
      if (filteredData.length === 0) {
        setIsNoDataFound(true);
      } else {
        setIsNoDataFound(false);
      }
    }, 3000);
  }, [filteredData]);

  // Set The Default Countries From Database
  const countriesData = locations.map((country) => {
    return {
      label: country,
      value: country,
    };
  });

  const industriesData = industries.map((industry) => {
    return {
      label: industry,
      value: industry,
    };
  });

  const prevPage = () => {
    // setFilteredData([]);
    if (page > 1) {
      setPage((page) => page - 1);
    }
  };

  const nextPage = () => {
    setFilteredData([]);
    if (page >= 1) {
      setPage((page) => page + 1);
    }
  };

  // console.log(page)
  // const setSelectedOptionParam = (val) => {
  //   setLocation(
  //     val.map((el) => {
  //       return el.name;
  //     })
  //   );

  //   // console.log(val)
  // };
  // console.log(isNoDataFound);

  return (
    <section className="startups">
      <div className="startups__header">
        <h1 className="heading-primary--main u-center-text">
          Find The Startups Here
        </h1>
      </div>

      <div className="startups__content">
        <div className="startups__content__filters">
          <div className="startups__content__filters__search">
            <input
              type="text"
              className="startups__content__filters__search__input"
              placeholder="Search Country/Startups"
              onChange={handleSearch}
            />
            <button
              className="btn startups__content__filters__search__submit"
              onClick={getSearchData}
            >
              Search
            </button>
          </div>

          {/* <SelectSearch
            options={countriesData}
            multiple
            search
            placeholder="Choose Location"
            onChange={setLocationParams}
            value={location}
            printOptions="on-focus"
            // closeOnSelect={false}
          />

          <SelectSearch
            options={industriesData}
            multiple
            search
            placeholder="Choose Industry"
            onChange={setIndustryParams}
            printOptions="on-focus"
            // closeOnSelect={false}
          /> */}

          <div className="startups__content__filters__dropdown">
            <Select
              // defaultValue={countriesData}
              onChange={setLocationParams}
              options={countriesData}
              isMulti={true}
              isSearchable={true}
              styles={customStyles}
              placeholder="Choose Location"
              className="startups__content__filters__dropdown__item"
            />

            <Select
              // defaultValue={countriesData}
              onChange={setIndustryParams}
              options={industriesData}
              isMulti={true}
              isSearchable={true}
              styles={customStyles}
              placeholder="Choose Industry"
              className="startups__content__filters__dropdown__item"
            />
          </div>

          <div className="startups__content__filters__pages">
            <button
              className="btn startups__content__filters__pages__button"
              onClick={prevPage}
            >
              Prev
            </button>
            <button
              className="btn startups__content__filters__pages__button"
              onClick={nextPage}
            >
              Next
            </button>
          </div>
        </div>
        {/* filteredData */}
        <div className="startups__content__cards">
          {filteredData.length > 0 ? (
            filteredData.map((comapny, key) => {
              // console.log(comapny)
              return (
                <div className="startups__content__cards__item" key={key}>
                  <img
                    src="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg"
                    className="startups__content__cards__item__image"
                  />
                  <h3 className="startups__content__cards__item__name">
                    {comapny.name}
                  </h3>
                  <p className="startups__content__cards__item__description">
                    Description
                  </p>
                </div>
              );
            })
          ) : !isNoDataFound ? (
            <Loader />
          ) : (
            <h1>No Data Found</h1>
          )}
        </div>
      </div>
    </section>
  );
};

export default Startups;

// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" },
// ];

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    // width: state.selectProps.width,
    borderBottom: "1px dotted pink",
    color: "#00baff",
    // padding: 20,
    fontSize: "1.4rem",
  }),

  // control: (_, { selectProps: { width, borderColor }}) => ({
  //   width: width,

  // }),

  control: (provided, state) => ({
    ...provided,
    borderColor: "#00baff",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    // width: state.selectProps.width,
    // borderBottom: '1px dotted pink',
    color: "#00baff",
    padding: "0 1rem",
    fontSize: "1.6rem",
    // backgroundColor: "blue"
  }),

  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // }
};
