import React from "react";
import { Translation } from "react-i18next";

const Header = (props) => {
  const { data } = props;
  return (
    <div className="team__content__grid__header">
      <Translation>
        {(t) => (
          <h1 className="team__content__grid__header__subheading">
            {t(data[0])}
          </h1>
        )}
      </Translation>
      <Translation>
        {(t) => (
          <h1 className="team__content__grid__header__heading">
            <span>{t(data[1])}</span>
          </h1>
        )}
      </Translation>
    </div>
  );
};

export default Header;
