import React from "react";
import { Translation } from "react-i18next";

const ShakaijinAccordion = (props) => {
    const { data } = props;

    const title = data[0];
    const content = data[1];

    return (
        <Translation>
            {(t) => (
                <div>
                    <div className="sha-tabs">
                        <div className="sha-tab">
                            <input type="checkbox" id={data[0]} />
                            <label className="sha-tab-label" htmlFor={data[0]}>
                                {t(title)}
                            </label>
                            <div className="sha-tab-content">{t(content)}</div>
                        </div>
                    </div>
                </div>
            )}
        </Translation>
    );

}

export default ShakaijinAccordion