import React, { Component } from "react";
import i18n from "i18next";
import { Translation } from "react-i18next";

class Navbar extends Component {
  state = {
    langValue: "en",
  };

  componentDidMount = () => {
    const currentLanguage = localStorage.getItem("currLang");

    // 2. Selecting default value for language
    document.getElementById("language-selector").value = currentLanguage
      ? currentLanguage
      : this.state.langValue;

    // 3. Setting state for the same
    this.setState({
      langValue: currentLanguage,
    });
  };

  // Change language
  changeLang = (e) => {
    const newLang = e.target.value;
    i18n.changeLanguage(newLang);

    // Setting language in local storage
    localStorage.setItem("currLang", newLang);
  };

  // Close the navbar
  closeNavbar = () => {
    document.getElementById("myNav").style.width = "0%";
  };

  // Open the navbar
  openNavbar = () => {
    document.getElementById("myNav").style.width = "100%";
  };
  render() {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("nav").style.top = "0";
      } else {
        document.getElementById("nav").style.top = "-15rem";
      }
      prevScrollpos = currentScrollPos;
    };
    return (
      <div>
        {/* Navigation starts now */}
        <div className="nav" id="nav">
          <a className="nav__logo" href="/">
            <img
              src="/assets/images/logo-black.png"
              className="nav__logo__image"
            />
          </a>
          <nav className="navigation" id="myNav">
            <a
              href="#"
              className="navigation__close"
              onClick={this.closeNavbar}
            >
              &times;
            </a>

            <ul className="navigation__nav">
              <li className="navigation__item">
                <Translation>
                  {(t) => (
                    <a className="navigation__link" href="/about-us#services">
                      {t("Services")}
                    </a>
                  )}
                </Translation>
              </li>
              <li className="navigation__item">
                <Translation>
                  {(t) => (
                    <a className="navigation__link" href="/shakaijin">
                      {t("Incubation")}
                    </a>
                  )}
                </Translation>
              </li>
              <li className="navigation__item">
                <Translation>
                  {(t) => (
                    <a className="navigation__link" href="https://connect.investocracy.co">
                      {t("Practice your pitch")}
                    </a>
                  )}
                </Translation>
              </li>

              <li className="navigation__item">
                <Translation>
                  {(t) => (
                    <a
                      className="navigation__link"
                      href="https://invc.news"
                      target="_blank" rel="noopener noreferrer"
                    >
                      {t("INVC News")}
                    </a>
                  )}
                </Translation>
              </li>
{/*
              <li className="navigation__item">
                <Translation>
                  {(t) => (
                    <a
                      className="navigation__link"
                      href="/startups"
                    >
                      {t("Startups")}
                    </a>
                  )}
                </Translation>
              </li>
*/}
{/* 
              <li className="navigation__item">
                <Translation>
                  {(t) => (
                    <a className="navigation__link" href="/reports">
                      {t("Reports")}
                    </a>
                  )}
                </Translation>
              </li>
*/}
{/* 
              <li className="navigation__item">
                <Translation>
                  {(t) => (
                    <a className="navigation__link" href="#events">
                      {t("Events")}
                    </a>
                  )}
                </Translation>
              </li>
*/}
              <select
                className="navigation__dropdown"
                onChange={this.changeLang}
                id="language-selector"
              >
                <Translation>
                  {(t) => (
                    <option value="en" className="navigation__dropdown__items">
                      {t("English")}
                    </option>
                  )}
                </Translation>
                <Translation>
                  {(t) => (
                    <option value="jp" className="navigation__dropdown__items">
                      {t("Japanese")}
                    </option>
                  )}
                </Translation>
              </select>
            </ul>
          </nav>
          <span className="navigation__open" onClick={this.openNavbar}>
            &#9776;
          </span>
        </div>

        <div className="popup" id="platform">
          <div className="popup__content">
            <a href="#" className="popup__close">
              &times;
            </a>
            <Translation>
              {(t) => (
                <h2 className="heading-primary--main u-margin-bottom-small popup__content__heading">
                  {t("Platform")}
                </h2>
              )}
            </Translation>

            <Translation>
              {(t) => (
                <h2 className="heading-secondary--main u-margin-bottom-small popup__content__heading">
                  {t("Coming soon.")}
                </h2>
              )}
            </Translation>

            <Translation>
              {(t) => (
                <p className="popup__content__text">
                  {t("Please check this space again by")}
                  <strong>{t("15 August.")}</strong>
                </p>
              )}
            </Translation>
          </div>
        </div>

        <div className="popup" id="events">
          <div className="popup__content">
            <a href="#" className="popup__close">
              &times;
            </a>
            <Translation>
              {(t) => (
                <h2 className="heading-primary--main u-margin-bottom-small popup__content__heading">
                  {t("Events")}
                </h2>
              )}
            </Translation>

            <Translation>
              {(t) => (
                <h2 className="heading-secondary--main u-margin-bottom-small popup__content__heading">
                  {t("Coming soon.")}
                </h2>
              )}
            </Translation>
            <Translation>
              {(t) => (
                <p className="popup__content__text">
                  {t("Please check this space again by")}
                  <strong>{t("10th July.")}</strong>
                </p>
              )}
            </Translation>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
