import React, { Component } from 'react';
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "i18next";


class ShaHeader extends Component {
    state = {
        langValue: "en",
    };

    componentDidMount = () => {
        const currentLanguage = localStorage.getItem("currLang");
        // 2. Selecting default value for language
        document.getElementById("language-selector").value = currentLanguage
            ? currentLanguage
            : this.state.langValue;

        // 3. Setting state for the same
        this.setState({
            langValue: currentLanguage,
        });
    };


    changeLang = (e) => {
        const newLang = e.target.value;
        i18n.changeLanguage(newLang);

        // Setting language in local storage
        localStorage.setItem("currLang", newLang);
    }

    render() {
        return (
            <div className="brand-row" >
                <Link to="/shakaijin" style={{ textDecoration: 'none' }}>
                    <img className="logo-new" src='/assets/images/fmv.png'></img>
                </Link>
                <Link to="/shakaijin" style={{ textDecoration: 'none' }}>

                    <div className="shakaijin-logo-txt">
                        <h1 className="shakaijin-logo-maintxt">Shakaijin</h1> <br></br>
                        <h3 className="shakajin-logo-subtxt">by Investocracy</h3>
                    </div>
                </Link>


                
                <button className="shakaijin-faq-btn" onclick="alert('Hello World')"></button>
                <select
                    className="shakaijin-change-lang-btn"
                    onChange={this.changeLang}
                    id="language-selector">
                    <Translation>
                        {(t) => (
                            <option value="en" className="dropdown-txt">
                                {t(" English ")}

                            </option>
                        )}
                    </Translation>
                    <Translation>
                        {(t) => (
                            <option value="jp" className="dropdown-txt">
                                {t("日本語")}
                            </option>
                        )}
                    </Translation>
                </select>
            </div>
        )
    }
}
export default ShaHeader;