import React from "react";
import { Translation } from "react-i18next";

const renderTags = (tagsArr) => {
  for(let i = 0; i < tagsArr.length; i++) {
    var $nav = ("<p/>", {
      className: 'reports__content__grid__content__member__position',
      Text: tagsArr[i],
    });
    $nav.appendTo(document.getElementsByClassName("reports__content__grid__content__member"));
  }
}

const Item = (props) => {
  const { data } = props;
  /* Data Parts: [index]: FileDescription (Example)
        [0]: Image ("assets/images/arpit-profile-photo.jpg")
        [1]: ReportName ("Global Startup Report vol.06 Thailand")
        [2]: Tags (["Technology", "Medicine", "Hot Topic"])
  */

  // Need to add Translation for renderTags onLoad
  return (
    <div className="reports__content__grid__content__member">
      <img
        src={data[0]}
        className="reports__content__grid__content__member__image"
      />
      <Translation>
        {(t) => (
          <h1 className="reports__content__grid__content__member__name">
            {t(data[1])}
          </h1>
        )}
      </Translation>
      <Translation>
        {(t) => (
          <h3 className="reports__content__grid__content__member__position">
            {t(data[2])}
          </h3>
        )}
      </Translation>
    </div>
  );
};

export default Item;
