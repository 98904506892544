import React from "react";
import loadable from '@loadable/component'
import LazyLoader from '../utils/LazyLoader';
import Header from "../team/Header";
import Popup from "../team/Popup";

// const Header = loadable(() => import("../team/Header"), {
//   fallback: <LazyLoader />,
// })
const Item = loadable(() => import("../team/Item"), {
  fallback: <LazyLoader />,
})
// const Popup = loadable(() => import("../team/Popup"), {
//   fallback: <LazyLoader />,
// })
// import Item from "../team/Item";
// 

const Team = () => {
  return (
    <main className="team">
      <div className="team__content">
        {/* Board Member Team */}
        <div className="team__content__grid">
          <Header data={["Who's got the vision?", "Project Leads"]} />

          <div className="team__content__grid__content">
          <a
              href="#Kamlesh-Jain"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/kamlesh-jain.jpg",
                  "Kamlesh Jain",
                  "Co-Founder and CEO",
                ]}
              />
            </a>
            <Popup
              data={[
                "Kamlesh-Jain",
                "Kamlesh Jain",
                [
                  "Medical Doctor with over 30 years of experience. Works closely with medical tech startups based in India and abroad. Specialist in Paediatrics.",
                ],
                "",
              ]}
            />
            
            <a
              href="#Anupreeti-Dhanapal"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/anupreeti-dhanapal.png",
                  "Anupreeti Dhanapal",
                  "Co-Founder",
                ]}
              />
            </a>
            <Popup
              data={[
                "Anupreeti-Dhanapal",
                "Anupreeti Dhanapal",
                [
                  "Expert medical surgeon Doctor with over 10 years of experience. Works closely with medical, health and edu-tech startups based in India and abroad. Specialist in Opthalmology.",
                ],
                "",
              ]}
            />

            <a
              href="#Arpit-Jain"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/arpit-profile-photo.jpg",
                  "Arpit Jain",
                  "Project Lead",
                ]}
              />
            </a>
            <Popup
              data={[
                "Arpit-Jain",
                "Arpit Jain",
                [
                  "Avid programmer based in Tokyo having a penchant for the world of Venture Capital. Has always followed startups from emerging markets and helped fundraise for clients. IIT-Kanpur Alumni who has travelled to 30 countries, backpacking through 16 of them.",
                ],
                "https://www.linkedin.com/in/arpitjain099/",
              ]}
            />

            <a
              href="#Gaurav-Bhardwaj"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/gaurav-bhardwaj.jpeg",
                  "Gaurav Bhardwaj",
                  "Project Lead",
                ]}
              />
            </a>
            <Popup
              data={[
                "Gaurav-Bhardwaj",
                "Gaurav Bhardwaj",
                [
                  "Having an experience in handling strategies for national restaurant chains of India for a leading food tech startup - Zomato. Launched Hokkaido region for Oyo Japan and was part of the founding team of 2 tech startups previously.",
                ],
                "https://www.linkedin.com/in/gauravbhardwajj/",
              ]}
            />

            <a
              href="#Lakshay-Garg"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/lakshay-garg.jpeg",
                  "Lakshay Garg",
                  "Project Lead",
                ]}
              />
            </a>
            <Popup
              data={[
                "Lakshay-Garg",
                "Lakshay Garg",
                [
                  "Interested in Venture Capital and Private Equity. Did his internship at BCG. Currently studying at Indian Institute of Management, Calcutta. IIT Kanpur Alumnus.",
                ],
                "https://www.linkedin.com/in/gauravbhardwajj/",
              ]}
            />

            
          </div>
        </div>

        <div className="team__content__grid">
          <Header data={["Who's advising us?", "Advisors"]} />
          <div className="team__content__grid__content">
          <a
              href="#Amandeep-Singh"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/Amandeep-profile-photo.jpeg",
                  "Amandeep Singh",
                  "Advisor",
                ]}
              />
            </a>
            <Popup
              data={[
                "Amandeep-Singh",
                "Amandeep Singh",
                [
                  "Amandeep is leading corporate planning and venture partnerships for emerging markets, especially Sout/Southeast Asia, at Honda Motor in Tokyo, Japan. A polyglot, an epicure, and a bike tourer.",
                ],
                "https://www.linkedin.com/in/theadsingh",
              ]}
            />

            <a
              href="#Mayank-Shiromani"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/Mayank-profile-photo.jpeg",
                  "Mayank Shiromani",
                  "Advisor",
                ]}
              />
            </a>
            <Popup
              data={[
                "Mayank-Shiromani",
                "Mayank Shiromani",
                [
                  "Mayank has been working with top notch corporate venture capital firms over his 10 work experience in Japan. He has worked as a Venture Capitalist with Recruit Strategic Partners and Mizuho Bank Japan. Mayank is also an investor in multiple startups Figure.com (Provenance.io), BlueVine, Fundbox, Liquidity Capital, ChargeAfter, Veem.",
                ],
                "https://www.linkedin.com/in/mayankshiromani/",
              ]}
            />
            </div>
        </div>

        {/* Developer Team */}
        <div className="team__content__grid">
          <Header data={["Who's building it", "Our Developer Team"]} />

          <div className="team__content__grid__content">
            
          <a
              href="#Charles-Liu"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/charles-liu.jpeg",
                  "Charles Liu",
                  "Software Developer",
                ]}
              />
            </a>
            <Popup
              data={[
                "Charles-Liu",
                "Charles Liu",
                [
                  "Charles is a Business & Computer Science student at the University of British Columbia, whose dream is to work in an overlapping field between technology and business! He's a geek for software solutions, financial markets, strategy consulting, and more. In his spare time, you'll find him on the badminton court or watching Breaking Bad on Netflix.",
                ],
                "https://www.linkedin.com/in/charlesliu205/",
              ]}
            />


            {/* molly */}
            <a
              href="#Molly-Xu"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/molly.png",
                  "Molly Xu",
                  "Software Developer",
                ]}
              />
            </a>
            <Popup
              data={[
                "Molly-Xu",
                "Molly Xu",
                [
                  "Molly is a prospective Computer Science and Business Administration double degree student at the University of Waterloo. Passionate about learning, she will continue acquiring new skills in tech and applying them for social good. When she isn’t coding, you can find her listening to Latin pop or playing volleyball.",
                ],
                "https://www.linkedin.com/in/mollyxu9/",
              ]}
            />

            {/* rex */}
            <a
              href="#Rex-Liu"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/rex.png",
                  "Rex Liu",
                  "Software Developer",
                ]}
              />
            </a>
            <Popup
              data={[
                "Rex-Liu",
                "Rex Liu",
                [
                  "Rex is a Computer Science student at UC Berkeley, passionate and skilled in web and mobile applications development. Also an avid badminton and tennis player.",
                ],
                "https://www.linkedin.com/in/rexliu3/",
              ]}
            />

          </div>
        </div>

        

          
        {
        <div className="team__content__grid">
          <Header data={["Who's crunching the data", "VC Research Team"]} />

          <div className="team__content__grid__content">
          <a
              href="#Kashish-Khosla"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/kashish.jpeg",
                  "Kashish Khosla",
                  "VC Research",
                ]}
              />
            </a>
            <Popup
              data={[
                "Kashish-Khosla",
                "Kashish Khosla",
                [
                  "Student at Ramjas College, Delhi University. Interested in Finance, Public Equity Markets and Startup Investments.",
                ],
                "https://www.linkedin.com/in/kashish-khosla/",
              ]}
            />
          
          <a
              href="#Adyasha-Padhy"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/adyasha.jpeg",
                  "Adyasha Padhy",
                  "VC Research",
                ]}
              />
            </a>
            <Popup
              data={[
                "Adyasha-Padhy",
                "Adyasha Padhy",
                [
                  "Student at MBA Tech course at Narsee Monjee Institute, Mumbai. Passionate about finance and impact investing",
                ],
                "https://www.linkedin.com/in/adyasha-padhy-458240142/",
              ]}
            />
            
          </div>
        </div>
        }

        {/* VC Research Team */}
        <div className="team__content__grid">
          <Header data={["Our Alumni", "Previous Team Members"]} />

          <div className="team__content__grid__content">

          <a
              href="#Sachin-Kr-Shukla"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/sachin-kr-shukla.jpg",
                  "Sachin Kr. Shukla",
                  "Developer",
                ]}
              />
            </a>
            <Popup
              data={[
                "Sachin-Kr-Shukla",
                "Sachin Kr. Shukla",
                [
                  "Sachin builds tech products that helps investors and VCs connect with their next potential startups.",
                  "Being a self-skilled person, he helps product team from ideation to design to development.",
                ],
                "https://www.linkedin.com/in/imsks/",
                "https://www.sachinshukla.me",
              ]}
            />

            <a
              href="#Simran-Kaur"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/simran.jpeg",
                  "Simran Kaur",
                  "VC Research",
                ]}
              />
            </a>
            <Popup
              data={[
                "Simran-Kaur",
                "Simran Kaur",
                [
                  "Student at Sri Guru Gobind Singh College Of Commerce, Delhi University. Interested in Finance, Startup Investments and VC Research",
                ],
                "https://www.linkedin.com/in/simran-kaur-2b81951a0/",
              ]}
            />

            <a
              href="#Shehnaz-Ghadiali"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/shehnaz.jpeg",
                  "Shehnaz Ghadiali",
                  "VC Research",
                ]}
              />
            </a>
            <Popup
              data={[
                "Shehnaz-Ghadiali",
                "Shehnaz Ghadiali",
                [
                  "Student at Trinity College, Dublin. Interested in Finance, Startup Investments and VC Research. CFA Level 1 Holder.",
                ],
                "https://www.linkedin.com/in/shehnaz-ghadiali/",
              ]}
            />

            <a
              href="#Anuja-Kutumbe"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/anuja.jpeg",
                  "Anuja Kutumbe",
                  "VC Research",
                ]}
              />
            </a>
            <Popup
              data={[
                "Anuja-Kutumbe",
                "Anuja Kutumbe",
                [
                  "Student at University of Mumbai. Interested in Finance, Startup Investments and VC Research.",
                ],
                "https://www.linkedin.com/in/anuja-kutumbe-3b8b5450/",
              ]}
            />

            <a
              href="#Jenil-Jain"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/jenil.png",
                  "Jenil Jain",
                  "VC Research",
                ]}
              />
            </a>
            <Popup
              data={[
                "Jenil-Jain",
                "Jenil Jain",
                [
                  "Student at University of Mumbai. Interested in Finance, Startup Investments and VC Research.",
                ],
                "",
              ]}
            />

            <a
              href="#Komal-Pattanayak"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/komal-pattanayak.jpeg",
                  "Komal Pattanayak",
                  "Content Writer",
                ]}
              />
            </a>
            <Popup
              data={[
                "Komal-Pattanayak",
                "Komal Pattanayak",
                [
                  "Komal Pattanayak hails from Odisha and has done her B.A. (hons) in Economics from Miranda House, Delhi University and is a content writer at Investocracy. She justifies her name by being a kind, soft hearted and everybody’s go-to person.",
                  "Also a trained Odissi dancer, she has a hunger for knowledge and knack for writing and delivers gripping articles for our readers. A mixture of diligence and punctuality, she advocates for hard work and smart work.",
                ],
                "https://www.linkedin.com/in/komal-pattanayak-94ba63146/",
              ]}
            />


          <a href="#Sarah-Wig" className="team__content__grid__content__link">
              <Item
                data={[
                  "assets/images/sarah-wig.jpg",
                  "Sarah Wig",
                  "Content Writer",
                ]}
              />
            </a>
            <Popup
              data={[
                "Sarah-Wig",
                "Sarah Wig",
                [
                  "Sarah is pursuing Economics and Psychology at Grinnell College (USA) and is passionate about the connections between these that help analyse consumer behaviour. She is an elegant yet prolific content writer at Investocracy who loves to play basketball and football. Sarah lives by “work hard, play hard”.",
                ],
                "https://www.linkedin.com/in/sarah-wig-5677991ab/",
              ]}
            />

            <a
              href="#Reddi-Sai-Vaishnavi"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/vaishnavi.jpeg",
                  "Reddi Sai Vaishnavi",
                  "VC Research",
                ]}
              />
            </a>
            <Popup
              data={[
                "Reddi-Sai-Vaishnavi",
                "Reddi Sai Vaishnavi",
                [
                  "MS in Enterprise and Entrepreneurship from University of Leeds, UK. Passionate about innovative social enterprises and startups that have an impact on society.",
                ],
                "https://www.linkedin.com/in/sai-vaishnavi-reddi-2803aa173/",
              ]}
            />

            <a
              href="#Soumyajit-Ghosh"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/soumyajit.jpeg",
                  "Soumyajit Ghosh",
                  "VC Research",
                ]}
              />
            </a>
            <Popup
              data={[
                "Soumyajit-Ghosh",
                "Soumyajit Ghosh",
                [
                  "Soumyajit finds it exciting to jump into diverse subjects, fields ranging from Strategy, Finance, Equity Research to Graphology, Numerology, etc. Research works always excite him.",
                  "He is keen on the start-up ecosystem and dreams to run a start-up in the coming future. Soumyajit is Chemical Engineer from Jadavpur University and MBA graduate from IIM Bangalore.",
                  "During his MBA, he has won multiple case competitions. He has four years of work-ex at GAIL (India) Ltd.",
                ],
                "https://www.linkedin.com/in/soumyajit-ghosh-090b5047/",
              ]}
            />

            <a
              href="#Ashwin-Ballal"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/ashwin.jpeg",
                  "Ashwin Ballal",
                  "VC Research",
                ]}
              />
            </a>
            <Popup
              data={[
                "Ashwin-Ballal",
                "Ashwin Ballal",
                [
                  "Hardworking and efficient, Ashwin prides himself on always completing any given task to the best of his ability. He is always looking to broaden his horizons and acquire knowledge across a diverse set of fields.",
                  "An ardent sports fan, Ashwin also pursues his passion by writing analysis articles on sports such as cricket and football.",
                ],
                "https://in.linkedin.com/in/ashwin-ballal-b449871a7",
              ]}
            />

            <a
              href="#Aashish-Bansal"
              className="team__content__grid__content__link"
            >
              <Item
                data={[
                  "assets/images/aashish-bansal.png",
                  "Aashish Bansal",
                  "VC Research",
                ]}
              />
            </a>
            <Popup
              data={[
                "Aashish-Bansal",
                "Aashish Bansal",
                [
                  "Aashish Bansal is a business strategy enthusiast currently pursuing his MBA in Finance and marketing at IIM Raipur. He's worked for 2 years in core market research and pre sales and support roles giving him both a startup and an MNC exposure. He is an Automotive enthusiast who has also completed his engineering in the same field. His interests lie in understanding the mysterious workings of the world through numbers and logic.",
                ],
                "https://www.linkedin.com/in/aashish-bansal-35ab179b/",
              ]}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Team;