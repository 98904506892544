import React from "react";
import { Translation } from "react-i18next";
import loadable from "@loadable/component";
import LazyLoader from "../utils/LazyLoader";

const WhatWeAre = loadable(() => import("../sections/WhatWeAre"), {
  fallback: <LazyLoader />,
});
const WhatWeDo = loadable(() => import("../sections/WhatWeDo"), {
  fallback: <LazyLoader />,
});
const Investors = loadable(() => import("../sections/Investors"), {
  fallback: <LazyLoader />,
});
const Startups = loadable(() => import("../sections/Startups"), {
  fallback: <LazyLoader />,
});
/*
const Team = loadable(() => import("../sections/Team"), {
  fallback: <LazyLoader />,
});
*/
const ReadyToJoin = loadable(() => import("../sections/ReadyToJoin"), {
  fallback: <LazyLoader />,
});

// const WhatWeAre = lazy(() => import("../sections/WhatWeAre"));
// const WhatWeDo = lazy(() => import("../sections/WhatWeDo"));
// const Investors = lazy(() => import("../sections/Investors"));
// const Startups = lazy(() => import("../sections/Startups"));
// const Team = lazy(() => import("../sections/Team"));
// const ReadyToJoin = lazy(() => import("../sections/ReadyToJoin"));

const LandingPage = () => {
  return (
    <main className="landingpage">
      <header className="landingpage__header">
        <section className="landingpage__header__section">
          <div className="landingpage__header__section__content">
            <div className="landingpage__header__section__content__text">
              <Translation>
                {(t) => (
                  <h1 className="landingpage__header__section__content__text__heading">
                    {t("We help you find investors who are")}
                    <span>{t("willing to work with you.")}</span>
                  </h1>
                )}
              </Translation>
              <Translation>
                {(t) => (
                  <h4 className="landingpage__header__section__content__text__info">
                    {t("Investocracy = Investment + Democracy")}
                  </h4>
                )}
              </Translation>
              <a href="https://forms.gle/od1YogVatujbBF8X9" target="_blank">
                <Translation>
                  {(t) => (
                    <button className="btn btn-md">
                      {t("Register Your Venture")}
                    </button>
                  )}
                </Translation>
              </a>
            </div>
            <div className="landingpage__header__section__content__image">
              <img
                src="assets/images/header-image.png"
                className="landingpage__header__section__content__image__item"
              />
            </div>
          </div>
        </section>
        {/*
        <div className="popup" id="register-your-venture">
          <div className="popup__content">
            <a href="#" className="popup__close">
              &times;
            </a>
            <Translation>
              {(t) => (
                <h2 className="heading-primary--main u-margin-bottom-small popup__content__heading">
                  {t("Register Your Venture")}
                </h2>
              )}
            </Translation>

            <Translation>
              {(t) => (
                <h2 className="heading-secondary--main u-margin-bottom-small popup__content__heading">
                  {t("Coming soon.")}
                </h2>
              )}
            </Translation>

            <Translation>
              {(t) => (
                <p className="popup__content__text">
                  {t("Please check this space again by")}
                  <strong>{t("10th July.")}</strong>
                </p>
              )}
            </Translation>
          </div>
        </div>
        */}
      </header>

      <WhatWeAre />
      <ReadyToJoin /> 
      <WhatWeDo />
      <Investors />
      <Startups />
      {/* <Team /> */}
    </main>
  );
};

export default LandingPage;
