import React, { useState } from "react";
import { Translation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";

var FontAwesome = require("react-fontawesome");

const Footer = (event, data) => {
  const [isNewsletterSubscribed] = useState(false);

  // fb pixel integration
  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };

  ReactPixel.init("345498396849141", advancedMatching, options);

  ReactPixel.pageView(); // For tracking page view
  ReactPixel.track(event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
  ReactPixel.trackSingle("PixelId", event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
  ReactPixel.trackCustom(event, data); // For tracking custom events
  ReactPixel.trackSingleCustom("PixelId", event, data); // For tracking custom events

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__content__about">
          <a className="footer__content__about__image" href="/">
            <img
              src="/assets/images/logo-white.png"
              className="footer__content__about__image__item" alt="Investocracy Logo"
            />
          </a>

          <div className="footer__content__about__social">
            <a
              href="https://www.facebook.com/investocracyhq/"
              className="footer__content__about__social__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesome
                className="footer__content__about__social__link__item"
                name="facebook-square"
              />
            </a>
            <a
              href="https://www.instagram.com/investocracyhq/"
              className="footer__content__about__social__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesome
                className="footer__content__about__social__link__item"
                name="instagram"
              />
            </a>
            <a
              href="https://twitter.com/investocracyhq"
              className="footer__content__about__social__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesome
                className="footer__content__about__social__link__item"
                name="twitter"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/investocracyjapan/"
              className="footer__content__about__social__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesome
                className="footer__content__about__social__link__item"
                name="linkedin-square"
              />
            </a>
            <a
              href="https://angel.co/company/investocracy/jobs"
              className="footer__content__about__social__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesome
                className="footer__content__about__social__link__item"
                name="angellist"
              />
            </a>
          </div>
        </div>
        <div className="footer__content__links">
          <Translation>
            {(t) => (
              <h1 className="footer__content__links__heading">
                {t("Company")}
              </h1>
            )}
          </Translation>
          <li className="footer__content__links__list">
            <Translation>
              {(t) => (
                <a
                  href="/about-us"
                  className="footer__content__links__list__item"
                >
                  {t("About us")}
                </a>
              )}
            </Translation>
          </li>

          <li className="footer__content__links__list">
            <Translation>
              {(t) => (
                <a href="/team" className="footer__content__links__list__item">
                  {t("Team")}
                </a>
              )}
            </Translation>
          </li>
          <li className="footer__content__links__list">
            <Translation>
              {(t) => (
                <a
                  href="https://angel.co/company/investocracy/jobs"
                  className="footer__content__links__list__item"
                >
                  {t("Jobs")}
                </a>
              )}
            </Translation>
          </li>
                <br></br>
          <Translation>
            {(t) => (
              <h1 className="footer__content__links__heading">
                {t("Products")}
              </h1>
            )}
          </Translation>
          <li className="footer__content__links__list">
            <Translation>
              {(t) => (
                <a
                  href="https://connect.investocracy.co"
                  className="footer__content__links__list__item"
                >
                  {t("Connect - Platform to Practice Investment pitches")}
                </a>
              )}
            </Translation>
          </li>
          <li className="footer__content__links__list">
            <Translation>
              {(t) => (
                <a
                  href="/shakaijin"
                  className="footer__content__links__list__item"
                >
                  {t("Shakaijin - Incubator to build tech startups for Japan")}
                </a>
              )}
            </Translation>
          </li>
          <div className="popup" id="platform">
            <div className="popup__content">
              <a href="#" className="popup__close">
                &times;
              </a>
              <Translation>
                {(t) => (
                  <h2 className="heading-primary--main u-margin-bottom-small popup__content__heading">
                    {t("Platform")}
                  </h2>
                )}
              </Translation>

              <Translation>
                {(t) => (
                  <h2 className="heading-secondary--main u-margin-bottom-small popup__content__heading">
                    {t("Coming soon.")}
                  </h2>
                )}
              </Translation>

              <Translation>
                {(t) => (
                  <p className="popup__content__text">
                    {t("Please check this space again by")}
                    <strong>{t("15 August.")}</strong>
                  </p>
                )}
              </Translation>
            </div>
          </div>

          <li className="footer__content__links__list">
            <Translation>
              {(t) => (
                <a
                  href="https://invc.news"
                  className="footer__content__links__list__item"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("INVC News")}
                </a>
              )}
            </Translation>
          </li>
          {/* 
          <li className="footer__content__links__list">
            <Translation>
              {(t) => (
                <a
                  href="/reports"
                  className="footer__content__links__list__item"
                >
                  {t("Reports")}
                </a>
              )}
            </Translation>
          </li>
          */}
        </div>

        <div className="footer__content__more">
          <div className="footer__content__more__cta">
            <h2 className="footer__content__more__cta__heading">
              <Translation>
              {(t) => (
                <a
                  href="https://forms.gle/gpgSqrdLKNeSqYiu5"
                  className="footer__content__links__list__item"  target="_blank" rel="noopener noreferrer"
                >
                  {t("Have A Query?")}
                </a>
              )}
            </Translation>
            </h2>
            <a href="https://forms.gle/gpgSqrdLKNeSqYiu5" className="footer__content__more__cta__link"  target="_blank" rel="noopener noreferrer">
              <button className="btn footer__content__more__cta__button">
              <Translation>
              {(t) => (
                <a
                  href="/reports"
                  className="footer__content__links__list__item"
                >
                  {t("Drop us a message")}
                </a>
              )}
            </Translation>
              </button>
            </a>
          </div>

          <div className="footer__content__more__newsletter">
            {!isNewsletterSubscribed ? (
              <>
                <h2 className="footer__content__more__newsletter__heading">
                  <Translation>
              {(t) => (
                <a
                  href="https://forms.gle/shdStXF7cwvEh7B39" target="_blank" rel="noopener noreferrer"
                  className="footer__content__links__list__item"
                >
                  {t("Join our newsletter for weekly updates")}
                </a>
              )}
            </Translation>
                </h2>
                <a href="https://forms.gle/shdStXF7cwvEh7B39" className="footer__content__more__cta__link" target="_blank" rel="noopener noreferrer">
              <button className="btn footer__content__more__cta__button">
              <Translation>
              {(t) => (
                <a
                  href="/reports"
                  className="footer__content__links__list__item"
                >
                  {t("Subscribe")}
                </a>
              )}
            </Translation>
              </button>
            </a>
              </>
            ) : (
              <h2 className="footer__content__more__newsletter__heading">
                Thanks for subscribing the newsletter.
              </h2>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
