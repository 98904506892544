import React from "react";
import { Translation } from "react-i18next";
var FontAwesome = require("react-fontawesome");

const Popup = (props) => {
  const { data } = props;
  return (
    <div className="team__content__popup" id={data[0]}>
      <div className="team__content__popup__content">
        <a href="#" className="team__content__popup__close">
          &times;
        </a>
        <Translation>
          {(t) => (
            <h2 className="heading-primary--main u-margin-bottom-small team__content__popup__content__heading">
              {t(data[1])}
            </h2>
          )}
        </Translation>

        {Object.keys(data[2]).map((key, val) => {
          return (
            <Translation key={key}>
              {(t) => (
                <h4 className="heading-secondary--main u-margin-bottom-small team__content__popup__content__text">
                  {t(data[2][val])}
                </h4>
              )}
            </Translation>
          );
        })}

        <div className="team__content__popup__content__social">
          <div className="team__content__popup__content__social__button">
            {/* Linkedin */}
            {data[3] ? (
              <a
                href={data[3]}
                className="team__content__popup__content__social__button__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesome
                  className="team__content__popup__content__social__button__link__item"
                  name="linkedin-square"
                />
              </a>
            ) : (
              ""
            )}

            {/* Portfolio */}
            {data[4] ? (
              <a
                href={data[4]}
                className="team__content__popup__content__social__button__link"
                target="_blank" rel="noopener noreferrer"
              >
                <FontAwesome
                  className="team__content__popup__content__social__button__link__item"
                  name="globe"
                />
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
