import React, { useState } from "react";
import i18n from "i18next";
import { Translation } from "react-i18next";
import ShaHeader from "../layouts/ShaHeader";

const Shakaijin_New = () => {

    const [slideImagePath, setSlideImagePath] = useState("/assets/images/sha-slide-1.png")
    
    const changeSlide = (event) => {
        var name = event.target.className;
        if (name === "details-txt num1") {
            setSlideImagePath("/assets/images/sha-slide-1.png")
        } else if (name === "details-txt num2") {
            setSlideImagePath("/assets/images/sha-slide-2.png")
        } else if (name === "details-txt num3") {
            setSlideImagePath("/assets/images/sha-slide-3.png")
        } else {
            setSlideImagePath("/assets/images/sha-slide-4.png")
        }
    }

    return (
        <main className="Shakaijin_New">
            <header className="hero-header grid">
                <div className="header-content">
                    <ShaHeader />

                    <div className="sha-landing-hero-content">
                        <Translation>
                            {(t) => (
                                <div>
                                    <h1 className="sha-title">{t("Build Your Startup in Japan")}</h1>
                                    <h1 className="sha-sub-title">{t("FREE Incubation bootcamp program for business co-founders from Japan and technical co-founders from abroad")}</h1>
                                    <a href="https://forms.gle/eRvyF9EKwYwwisM29" target="_blank" rel="noopener noreferrer"><button className="sha-join-program-btn btn">{t("Join The Program")}</button></a>
                                    
                                    <a href="/shakaijin/faqs" target="_blank" rel="noopener noreferrer"><button className="sha-know-more-btn btn">{t("Know More")}</button></a>
                                </div>
                            )}
                        </Translation>
                    </div>
                </div>
            </header>

            <div className="sha-body">
                <Translation>
                    {(t) => (
                        <div>
                            <h1 className="sha-details-title">{t("Program Details")}</h1>
                            <div className="">
                                <div className="">
                                    <div className="program-details-component" onMouseOver={(e) => changeSlide(e)}>
                                        <img src="/assets/images/sha-details-1.png" className="details-img" alt="Shakaijin program Detail 1"></img>
                                        <span className="details-txt num1"> {t("Apply for the program and get ready for an interview with us.")} </span>
                                    </div>
                                    <div className="program-details-component" onMouseOver={(e) => changeSlide(e)}>
                                        <img src="/assets/images/sha-details-2.png" className="details-img" alt="Shakaijin program Detail 2"></img>
                                        <span className="details-txt num2"> {t("Successful applicants join our first cohort of founders.")} </span>
                                    </div>
                                    <div className="program-details-component" onMouseOver={(e) => changeSlide(e)}>
                                        <img src="/assets/images/sha-details-3.png" className="details-img" alt="Shakaijin program Detail 3"></img>
                                        <span className="details-txt num3"> {t("Undergo 12 weeks of rigorous training, build an MVP, and get early users for your tech startup.")} </span>
                                    </div>
                                    <div className="program-details-component" onMouseOver={(e) => changeSlide(e)}>
                                        <img src="/assets/images/sha-details-4.png" className="details-img" alt="Shakaijin program Detail 4"></img>
                                        <span className="details-txt num4"> {t("Pitch in front of investors and Japanese accelerators.")}</span>
                                    </div>
                                </div>
                                {/* 
                                <div className="sha-details-img">
                                    <img src={slideImagePath}></img>
                                </div>
                                */}
                            </div>
                        </div>
                    )}
                </Translation>


                <Translation>
                    {(t) => (
                        <div>
                        <h1 className="sha-details-title">{t("Why in Japan")}</h1>
                        <div className="">
                            <div className="">
                                <div className="program-details-component" onMouseOver={(e) => changeSlide(e)}>
                                    <img src="/assets/images/sha-details-1.png" className="details-img" alt="Shakaijin program Detail 5"></img>
                                    <span className="details-txt num1"> {t("Japan is the third biggest economy in the world with it's GDP around USD 5 Trillion.")} </span>
                                </div>
                                <div className="program-details-component" onMouseOver={(e) => changeSlide(e)}>
                                    <img src="/assets/images/sha-details-2.png" className="details-img" alt="Shakaijin program Detail 6"></img>
                                    <span className="details-txt num2"> {t("The number of tech startups in Japan is really low.")} </span>
                                </div>
                                <div className="program-details-component" onMouseOver={(e) => changeSlide(e)}>
                                    <img src="/assets/images/sha-details-3.png" className="details-img" alt="Shakaijin program Detail 7"></img>
                                    <span className="details-txt num3"> {t("Japanese government and corporations are pushing for more tech startups to be incorporated in Japan.")} </span>
                                </div>
                                <div className="program-details-component" onMouseOver={(e) => changeSlide(e)}>
                                    <img src="/assets/images/sha-details-4.png" className="details-img" alt="Shakaijin program Detail 8"></img>
                                    <span className="details-txt num4"> {t("Japan needs more technical cofounders.")}</span>
                                </div>
                            </div>
                            {/* 
                            <div className="sha-details-img">
                                <img src={slideImagePath}></img>
                            </div>
                            */}
                        </div>
                    </div>
                    )}

                </Translation>



            </div>
        </main >
    )
}

export default Shakaijin_New;