import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from './components/layouts/Navbar';
import Footer from './components/layouts/Footer';
import ShaFooter from './components/layouts/ShaFooter';
import LandingPage from './components/pages/LandingPage';
import Team from './components/pages/Team';
import Reports from './components/pages/Reports';
import ReportShowcase from './components/reports/ReportShowcase';
import AboutUs from './components/pages/AboutUs';
import FAQs from './components/pages/FAQs';
import Startups from './components/pages/startups/Startups';
import Contactus from './components/pages/Contactus';
import NotFoundPage from './components/pages/404';

import Shakaijin from './components/pages/Shakaijin';
import ShakaijinFAQs from './components/pages/ShakaijinFAQs';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

const trackingId = 'UA-111548671-3'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.set({
  //userId: auth.currentUserId(),

  // any data that is relevant to the user session
  // that you would like to track with google analytics
})

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

// console.log(process.env)

const App = () => {
  return (
    <BrowserRouter history={history}>
      <div className="App">
        {/* <Navbar /> */}
        <Switch>
          <Route exact path="/">
            <Navbar />
            <LandingPage />
            <Footer />
          </Route>

          <Route exact path="/team" >
            <Navbar />
            <Team />
            <Footer />
          </Route>

          <Route exact path="/reports" >
            <Navbar />
            <Reports />
            <Footer />
          </Route>

          <Route exact path="/reports/:reporturl" >
            <Navbar />
            <ReportShowcase />
            <Footer />
          </Route>

          <Route exact path="/about-us" >
            <Navbar />
            <AboutUs />
            <Footer />
          </Route>

          <Route exact path="/faq" >
            <Navbar />
            <FAQs />
            <Footer />
          </Route>

          <Route exact path="/startups" >
            <Navbar />
            <Startups />
            <Footer />
          </Route>

          <Route exact path="/contactus" >
            <Navbar />
            <Contactus />
            <Footer />
          </Route>

          <Route exact path="/shakaijin">
            <Shakaijin />
            <ShaFooter />
          </Route>

          <Route exact path="/shakaijin/FAQs">
            <ShakaijinFAQs />
            <ShaFooter />
          </Route>

          <Route path="*" >
            <Navbar />
            <NotFoundPage />
            <Footer />
          </Route>

        </Switch>
        {/* <Footer /> */}
      </div>
    </BrowserRouter>
  );
}

export default App;
