import React from "react";


const NotFoundPage = () => (
  <section className="notfound">
    <div className="notfound__content">
      <h1 className="notfound__content__label">Whoops, You landed on wrong page.</h1>
      <a href="/" className="notfound__content__link">
        <button className="btn-sm notfound__content__button">Go to home</button>
      </a>
    </div>
  </section>
);

export default NotFoundPage;
